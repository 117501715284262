window.__initMenu = (function () {
  let isInitialized = false;

  function initialize() {
    const maxWidth = 768,
      isSmallScreen = window.innerWidth < maxWidth;

    const menu = document.querySelector('#menu'),
      menuButton = document.querySelector('#menu-toggle'),
      pageWrapperMain = document.querySelector('#page-wrapper-main'),
      pageWrapperFooter = document.querySelector('#page-wrapper-footer'),
      body = document.body;

    function setAriaHiddenAttribute(value) {
      value
        ? menu.setAttribute('aria-hidden', 'true')
        : menu.setAttribute('aria-hidden', 'false');
    }

    if (isInitialized) {
      /**
       *
       * This could be a resize so we set the attribute accordingly
       *
       */

      if (!isSmallScreen) {
        // Clear up classes
        hideMenu();
        // Set the aria-attribute to the correct value
        setAriaHiddenAttribute(isSmallScreen);
      }

      return;
    }

    function hideMenu() {
      setAriaHiddenAttribute(true);

      body.classList.remove('overflow-no-scroll');
      pageWrapperMain.classList.remove('page-wrapper__item--hide');
      pageWrapperFooter.classList.remove('page-wrapper__item--hide');
    }

    function showMenu() {
      setAriaHiddenAttribute(false);

      body.classList.add('overflow-no-scroll');
      pageWrapperMain.classList.add('page-wrapper__item--hide');
      pageWrapperFooter.classList.add('page-wrapper__item--hide');
    }

    setAriaHiddenAttribute(window.innerWidth < maxWidth);

    menuButton.addEventListener('click', function () {
      let expanded = menuButton.getAttribute('aria-expanded') === 'true';

      menuButton.setAttribute('aria-expanded', !expanded);

      menu.getAttribute('aria-hidden') === 'true' ? showMenu() : hideMenu();
    });

    isInitialized = true;
  }

  window.addEventListener('resize', initialize);

  return initialize;
})();

if (document.querySelector('#menu')) {
  window.__initMenu();
}
